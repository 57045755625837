const routes = [
  {
    path: '/painel',
    name: 'panel',
    redirect: 'dashboard',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Index'),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Dashboard/Index')
      },
      {
        path: 'kanban-cotacoes',
        name: 'kanban-quotation',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Quotation/Kanban'),
        meta: {
          can: 'quotation-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        },
      },
      {
        path: 'listagem-cotacoes',
        name: 'list-quotation',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Quotation/List'),
        meta: {
          can: 'quotation-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        },
      },
      {
        path: 'detalhes-cotacao/:quotationId',
        name: 'details-quotation',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Quotation/Details'),
        props: true,
        meta: {
          can: 'quotation-show',
          onDeniedRoute: { name: 'quotation', replace: true }
        },
      },
      {
        path: 'listagem-apolices',
        name: 'list-policies',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Policies/List'),
        meta: {
          can: 'policy-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        },
      },
      {
        path: 'criar-editar-apolice/:policyId?',
        name: 'create-edit-policy',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Policies/CreateOrEdit'),
        props: true,
        meta: {
          anyCan: ['policy-update', 'policy-store', 'policy-edit'],
          onDeniedRoute: { name: 'quotation', replace: true }
        },
      },
      {
        path: 'detalhes-apolice/:policyId',
        name: 'details-policy',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Policies/Details'),
        props: true,
        meta: {
          can: 'policy-show',
          onDeniedRoute: { name: 'quotation', replace: true }
        },
      },
      {
        path: 'usuarios',
        name: 'list-users',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Users/List'),
        props: true,
        meta: {
          can: 'user-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'criar-editar-usuario/:userId?',
        name: 'create-edit-user',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Users/CreateOrEdit'),
        props: true,
        meta: {
          anyCan: ['user-edit', 'user-update', 'user-store'],
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'criar-sinistro/:policyId',
        name: 'create-claim',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Claims/Create'),
        props: true,
        meta: {
          anyCan: ['claim-store', 'claim-update', 'claim-edit'],
          onDeniedRoute: { name: 'quotation', replace: true }
        },
      },
      {
        path: 'listagem-sinistros/',
        name: 'list-claims',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Claims/List'),
        meta: {
          can: 'claim-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'listagem-certidoes/',
        name: 'certificate-list',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Certificates/List'),
        meta: {
          can: 'certificate-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'editar-certidoes/:identifier',
        name: 'edit-certificate',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Certificates/Edit'),
        props: true,
        meta: {
          can: 'certificate-import',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'detalhes-certidao/:identifier',
        name: 'details-certificate',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Certificates/Details'),
        props: true,
        meta: {
          can: 'certificate-show',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'criar-endosso/:policyId',
        name: 'create-endorsement',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Endorsement/Create'),
        props: true,
        meta: {
          anyCan: ['endorsement-store', 'endorsement-edit', 'endorsement-update'],
          onDeniedRoute: { name: 'quotation', replace: true }
        },
      },
      {
        path: 'detalhes-endosso/:endorsementId',
        name: 'details-endorsement',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Endorsement/Details'),
        props: true,
        meta: {
          can: 'endorsement-show',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'listagem-endossos/',
        name: 'list-endorsement',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Endorsement/Kanban'),
        meta: {
          can: 'endorsement-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'apoio-juridico/processos/',
        name: 'list-jurimetry',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Jurimetry/List'),
        meta: {
          can: 'process-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'detalhes-processo/:processId',
        name: 'details-process',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Jurimetry/Details'),
        props: true,
        meta: {
          can: 'process-show',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'carta-nomeacao',
        name: 'appointment-letter',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Docusign/Appointment/Create'),
        meta: {
          can: 'docusign-store',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'carta-nomeacao/sucesso',
        name: 'appointment-letter-success',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Docusign/Appointment/Success'),
        meta: {
          can: 'docusign-store',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'cliente/novo',
        name: 'client-store',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Client/CreateOrEdit'),
        meta: {
          anyCan: ['policy-update', 'policy-store', 'policy-edit'],
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'listagem-clientes/',
        name: 'list-client',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Client/List'),
        props: true,
        meta: {
          anyCan: 'policy-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'visualizar-cliente/:economicGroupID',
        name: 'show-client',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Client/Details'),
        meta: {
          anyCan: 'policy-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'atualizar-cliente/:economicGroupID',
        name: 'update-client',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Client/CreateOrEdit'),
        meta: {
          anyCan: 'policy-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
      {
        path: 'integracoes',
        name: 'list-integrations',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Integrations/List'),
        props: true,
        meta: {
          can: 'integration-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },      
      {
        path: 'kanban-renovacao/',
        name: 'kanban-renewal',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Panel/Policies/KanbanRenewal'),
        meta: {
          can: 'policy-list',
          onDeniedRoute: { name: 'quotation', replace: true }
        }
      },
    ]
  },
];

export default routes;
